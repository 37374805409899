import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../assets/img/illustration/01.jpeg";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">About Us</span>
                <h2 className="title">
                  Innovating Solutions
                  <br /> For Tomorrow's Challenges{" "}
                </h2>
              </div>
              <p className="mb-25">
                At Suchaye, we are dedicated to revolutionizing the IT industry
                through our comprehensive services in sourcing, project
                management, and product development.
              </p>
              <p>
                With a keen focus on innovation and efficiency, we strive to
                deliver tailored solutions that exceed client expectations.
              </p>
              <ul className="about-list">
                <li>
                  {" "}
                  <i className="far fa-check" /> Global &amp; Sourcing Network
                </li>
                <li>
                  {" "}
                  <i className="far fa-check" /> End-to-End Project & Product
                  Development
                </li>
              </ul>
              <Link to="/contact" className="main-btn">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
