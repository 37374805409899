import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import img1 from "../../../assets/img/video-bg/poster-1.jpeg";
import img2 from "../../../assets/img/video-bg/poster-2.jpeg";

function About(props) {
  //   const [openVideo, setOpenVideo] = useState(false);

  //   const openVideoModal = () => {
  //     setOpenVideo(true);
  //   };

  return (
    <section className="about-section section-gap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-video">
              <div
                className="video-poster-one wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
                style={{ backgroundImage: "url(" + img2 + ")" }}
              ></div>
              {/* <div
                className="video-poster-two wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
                style={{ backgroundImage: "url(" + img2 + ")" }}
              >
                <span
                  onClick={openVideoModal}
                  className="video-play-icon popup-video"
                  role="button"
                >
                  <i className="fas fa-play" />
                </span>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="about-text-two">
              <div className="section-title left-border mb-40">
                <span className="title-tag">About Us</span>
                <h2 className="title">
                  Professional & Innovative Sourcing and Development Solutions
                </h2>
              </div>
              <p>
                At Suchaye, we redefine sourcing and development through a
                fusion of cutting-edge technologies and unparalleled expertise.
                Our commitment to excellence and innovation sets us apart in the
                industry, empowering businesses to thrive in a rapidly evolving
                digital landscape.
              </p>
              <ul className="about-list">
                <li>
                  {" "}
                  <i className="fas fa-check" /> We provide personalized
                  sourcing and development services precisely tailored to each
                  project's unique requirements, ensuring alignment with
                  clients' goals.
                </li>
                <li>
                  {" "}
                  <i className="fas fa-check" /> Our agile approach fosters
                  collaboration, transparency, and flexibility, enabling us to
                  adapt swiftly to market changes and deliver superior outcomes
                  with faster time-to-market.
                </li>
                <li>
                  {" "}
                  <i className="fas fa-check" /> We curate high-performing teams
                  from a diverse global talent pool, ensuring the right
                  expertise for tackling complex IT challenges and driving
                  innovation while maintaining cost-effectiveness.
                </li>
              </ul>
              <Link to="/about" className="main-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <ModalVideo
        channel="youtube"
        autoplay
        isOpen={openVideo}
        videoId="fEErySYqItI"
        onClose={() => setOpenVideo(false)}
      /> */}
    </section>
  );
}

export default About;
