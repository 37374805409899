import React, { Component } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import illustration from "../../../assets/img/illustration/contactus.jpeg";

class Content extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }
  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_570yyou",
        "template_r4xxvqf",
        this.form.current,
        "jb-rLLjFQUP5WoFTI"
      )
      .then(
        () => {
          toast.success("New Request Submitted Successfully");
          this.form.current.reset();
        },
        (error) => {
          toast.error("Failed to Submit New Request. Please try again later");
          this.form.current.reset(); // Clear the form
        }
      );
  };
  render() {
    return (
      <>
        <ToastContainer />
        <section className="contact-section contact-page section-gap-top">
          <div className="container">
            <div className="contact-info">
              <div className="row justify-content-center">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="illustration-img text-center">
                    <img src={illustration} alt="" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                  <div className="contact-info-content">
                    <div className="section-title left-border mb-40">
                      <span className="title-tag">Get In Touch with Us</span>
                      <h2 className="title">
                        Need Any Help For Sourcing &amp; Development
                      </h2>
                    </div>
                    <p>
                      Our team at Suchaye is dedicated to providing top-notch
                      solutions tailored to your needs. Whether you're looking
                      for sourcing expertise or seeking to innovate your
                      products, we're committed to exceeding your expectations.
                    </p>
                    <ul>
                      <li className="phone">
                        <Link to="tel:+0123456789">
                          <i className="far fa-phone" />
                          +1 (904) 899-3003
                        </Link>
                      </li>
                      <li>
                        <i className="far fa-envelope-open" />
                        <Link to="#">sri@suchaye.com</Link>
                      </li>
                      <li>
                        <i className="far fa-map-marker-alt" />
                        Parkland, Florida, USA
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form grey-bg">
              <div className="row no-gutters justify-content-center">
                <div className="col-10">
                  <div className="section-title text-center mb-40">
                    <h2 className="title">Reach Out To Us</h2>
                  </div>
                  <form ref={this.form} onSubmit={this.sendEmail}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="input-group mb-30">
                          <input
                            type="text"
                            name="user_name"
                            placeholder="Your Full Name"
                          />
                          <span className="icon">
                            <i className="far fa-user-circle" />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-30">
                          <input
                            type="email"
                            name="user_email"
                            placeholder="Your Email Address"
                          />
                          <span className="icon">
                            <i className="far fa-envelope-open" />
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group mb-30">
                          <input
                            type="text"
                            name="user_phone"
                            placeholder="Your Phone"
                          />
                          <span className="icon">
                            <i className="far fa-phone" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group textarea mb-30">
                          <textarea
                            placeholder="Write Message"
                            defaultValue={""}
                            name="message"
                          />
                          <span className="icon">
                            <i className="far fa-pencil" />
                          </span>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button type="submit" className="main-btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid container-1600">
            <div className="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3575.3154601452898!2d-80.282977!3d26.348656900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d910a2058c692b%3A0xd4611fb212bd0335!2sSolstice%20Cir%2C%20Parkland%2C%20FL%2033076%2C%20USA!5e0!3m2!1sen!2sin!4v1713019566895!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Content;
